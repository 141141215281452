import { Link } from 'react-router-dom';
import { numberFormatCurrency } from '../../../utils/helper';

import { Tooltip } from '@material-ui/core';

export const frequencyCWTList = [
  {
    value: 'not_applicable',
    label: 'Not Applicable',
  },
  {
    value: 'within_collection',
    label: 'Within Collection',
  },
  {
    value: 'monthly',
    label: 'Monthly',
  },
  {
    value: 'quarterly',
    label: 'Quarterly',
  },
  {
    value: 'annually',
    label: 'Annually',
  },
];

export const customerColumns = [
  {
    field: 'customer_code',
    headerName: 'Customer Code',
    flex: 0,
    minWidth: 180,
    hide: false,
    renderCell: (customersData) => {
      return (
        <Link
          style={{ color: 'blue' }}
          to={`/view/customer/${customersData.row.customer_code}`}
        >
          {customersData.row.customer_code}
        </Link>
      );
    },
  },
  {
    field: 'customer_name',
    headerName: 'Customer Name',
    width: 250,
    hide: false,
    renderCell: (customersData) => {
      return (
        <Tooltip
          title={customersData.row.customer_name}
          placement="right-start"
        >
          <Link
            style={{ color: 'blue' }}
            to={`/view/customer/${customersData.row.customer_code}`}
          >
            {customersData.row.customer_name}
          </Link>
        </Tooltip>
      );
    },
  },
  {
    field: 'shipping_address',
    headerName: 'Shipping Address',
    flex: 0,
    minWidth: 180,
    hide: true,
  },
  {
    field: 'lba_code',
    headerName: 'LBA Code',
    flex: 0,
    minWidth: 180,
    hide: true,
  },
  {
    field: 'lba_name',
    headerName: 'LBA Name',
    flex: 0,
    minWidth: 180,
    hide: true,
  },

  {
    field: 'tin_no',
    headerName: 'Tin',
    flex: 0,
    minWidth: 180,
    hide: false,
  },
  {
    field: 'contact_person',
    headerName: 'Contact Person',
    flex: 0,
    minWidth: 180,
    hide: true,
  },
  {
    field: 'email_address',
    headerName: 'Email Address',
    flex: 0,
    minWidth: 180,
    hide: true,
  },
  {
    field: 'contact_no',
    headerName: 'Contact No.',
    flex: 0,
    minWidth: 180,
    hide: false,
  },
  {
    field: 'billing_address',
    headerName: 'Billing Address',
    flex: 0,
    minWidth: 180,
  },
  {
    field: 'billing_barangay',
    headerName: 'Billing Brgy',
    flex: 0,
    minWidth: 180,
  },
  {
    field: 'district',
    headerName: 'District',
    flex: 0,
    minWidth: 180,
  },
  {
    field: 'amount_outstanding',
    headerName: 'Amount Outstanding',
    flex: 0,
    minWidth: 180,
    type: 'number',
    align: 'right',
    sortable: false,
    hide: false,
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.amount_outstanding);
    },
  },

  {
    field: 'billing_payment_mode',
    headerName: 'Mode of Payment',
    flex: 0,
    minWidth: 180,
    hide: false,
    // valueFormatter: ({ value }) => value.payment_terms,
  },
  {
    field: 'payment_terms_text_format',
    headerName: 'Terms',
    flex: 0,
    minWidth: 180,
    hide: false,
    // valueFormatter: ({ value }) => value.payment_terms,
  },
  // {
  //   field: "initial_stocking_terms",
  //   headerName: "Initial Stock",
  //   flex: 0,
  //   minWidth: 180,
  //   hide: true,
  //   renderHeader: () => <label title="Initial Stock">{"Initial Stock"}</label>,
  //   //valueFormatter: ({ value }) => value.payment_terms,
  // },
  {
    field: 'channel_text_format',
    headerName: 'Channel',
    flex: 0,
    minWidth: 180,
    hide: false,
    //valueFormatter: ({ value }) => value.channel,
  },
  {
    field: 'tagging',
    headerName: 'Customer Tagging',
    flex: 0,
    minWidth: 180,
    hide: false,
    //valueFormatter: ({ value }) => value.channel,
  },
  {
    field: 'AO',
    headerName: 'AO',
    flex: 0,
    minWidth: 180,
    hide: false,
  },
  {
    field: 'customer_type_desc_text_format', //customer_type_desc_text_format
    headerName: 'Industry',
    flex: 0,
    minWidth: 180,
    hide: true,
    //valueFormatter: ({ value }) => value.customer_type_desc,
  },
  {
    field: 'past_due_rate',
    headerName: 'Past Due Rate',
    flex: 0,
    minWidth: 180,
    hide: true,
    sortable: false,
  },
  {
    field: 'dso_vs_credit_terms',
    headerName: 'DSO vs Credit Terms',
    flex: 0,
    minWidth: 180,
    hide: true,
    sortable: false,
  },
  {
    field: 'pay_ratio',
    headerName: 'Pay Ratio',
    flex: 0,
    minWidth: 180,
    hide: true,
    sortable: false,
  },
  {
    field: 'kam_name',
    headerName: 'Sales Manager',
    flex: 0,
    minWidth: 180,
    hide: false,
  },
  {
    field: 'kass_name',
    headerName: 'Sales Officer',
    flex: 0,
    minWidth: 180,
    hide: false,
  },
  {
    field: 'sar',
    headerName: 'SAR',
    flex: 0,
    minWidth: 180,
    hide: false,
  },
  {
    field: 'hsar',
    headerName: 'HSAR',
    flex: 0,
    minWidth: 180,
    hide: false,
  },
  {
    field: 'distribution_channel',
    headerName: 'Distribution Channel',
    flex: 0,
    minWidth: 180,
    hide: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0,
    minWidth: 180,
    hide: false,
    renderCell: (data) => {
      if (data.row.status === 1) {
        return 'Active';
      } else {
        return 'Inactive';
      }
    },
  },
  {
    field: 'cwt',
    headerName: 'CWT',
    flex: 0,
    minWidth: 180,
    hide: false,
  },
  {
    field: 'sc_discount',
    headerName: 'SC Discount',
    flex: 0,
    minWidth: 180,
    hide: false,
  },
];
