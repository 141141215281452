const SET_JV_FORM = 'SET_JV_FORM';
const SET_JV_FORM_FLAG = 'SET_JV_FORM_FLAG';
const SET_JV_FORM_CLEAR = 'SET_JV_FORM_CLEAR';
const SET_JV_OR = 'SET_JV_OR';
const SET_JV_OR_CLEAR = 'SET_JV_OR_CLEAR';

export function setJvOR(orList) {
  return {
    type: SET_JV_OR,
    orList,
  };
}

export function setJvForm(formData) {
  return {
    type: SET_JV_FORM,
    formData,
  };
}

export function setJvFormFlag(key, value) {
  return {
    type: SET_JV_FORM_FLAG,
    key,
    value,
  };
}

export function setJvFormClear() {
  return {
    type: SET_JV_FORM_CLEAR,
  };
}

export function setJvORClear() {
  return {
    type: SET_JV_OR_CLEAR,
  };
}

const initialState = {
  jvFlag: null,
  jvPrevFlag: null,
  jvForm: null,
  jvOR: null,
};

export default function journalVoucher(state = initialState, action) {
  switch (action.type) {
    case SET_JV_FORM_FLAG:
      return {
        ...state,
        [action.key]: action.value,
      };

    case SET_JV_FORM:
      return {
        ...state,
        jvForm: action.formData,
      };
    case SET_JV_FORM_CLEAR:
      return {
        ...state,
        jvForm: null,
        jvPrevFlag: null,
        jvOR: null,
      };

    case SET_JV_OR:
      return {
        ...state,
        jvOR: action.orList,
      };
    case SET_JV_OR_CLEAR:
      return {
        ...state,
        jvOR: null,
      };
    default:
      return state;
  }
}
