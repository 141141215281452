import { lazy } from 'react';

const MDProfile = lazy(() => import('../pages/MDProfile/index'));

const mdProfileRoute = {
  auth: [
    {
      name: 'accounting_code_profile',
      path: '/accounting-code-profile',
      component: MDProfile,
    },
  ],
};

export default mdProfileRoute;
