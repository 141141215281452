const SET_PV_FORM = 'SET_PV_FORM';
const SET_PV_FORM_FLAG = 'SET_PV_FORM_FLAG';
const SET_PV_FORM_CLEAR = 'SET_PV_FORM_CLEAR';

export function setPvForm(formData) {
  return {
    type: SET_PV_FORM,
    formData,
  };
}

export function setPvFormFlag(key, value) {
  return {
    type: SET_PV_FORM_FLAG,
    key,
    value,
  };
}

export function setPvFormClear() {
  return {
    type: SET_PV_FORM_CLEAR,
  };
}

const initialState = {
  pvFlag: null,
  pvPrevFlag: null,
  pvForm: null,
};

export default function payableVoucher(state = initialState, action) {
  switch (action.type) {
    case SET_PV_FORM_FLAG:
      return {
        ...state,
        [action.key]: action.value,
      };

    case SET_PV_FORM:
      return {
        ...state,
        pvForm: action.formData,
      };
    case SET_PV_FORM_CLEAR:
      return {
        ...state,
        pvForm: null,
        pvPrevFlag: null,
      };
    default:
      return state;
  }
}
